import React from "react";
import Accordion from "./Accordion";

function Faq() {
  return (
    <>
      <Accordion
        title="Mitä palvelun käyttöönotto vaatii?"
        trackId="mita-kayttoonotto-vaatii"
      >
        <p>
          Käyttöönoton yhteydessä tarvitsemme apuanne tuotetietojen ja kuvien
          kokoamisessa. Mallipalapalveluun tarvittavat tiedot ovat monesti
          hajallaan eri järjestelmissä. Tietojen toimittaminen saattaa
          osoittautua luultua työläämmäksi. Parhaassa tapauksessa tuotetiedot
          kuitenkin löytyvät muista järjestelmistä siten, että koko data saadaan
          ladattua esim. CSV:ksi ilman suurempia Excel-harjoituksia.
        </p>
        <p>
          Tuotetietojen järjestämisen lisäksi käyttöönotossa on ratkaistava mm.
          seuraavia käytännön kysymyksiä:
        </p>
        <ol>
          <li>
            Varastoidaanko valmiita mallipaloja vai tuotetaanko näitä sitä
            mukaa, kun tilauksia tulee?
          </li>
          <li>Miten mallipalat merkitään ja järjestetään lähettämöön?</li>
          <li>Millaisissa pakkauksissa ne lähetetään?</li>
        </ol>
        <p>
          Lisäksi markkinoinnin osaajilta tarvitaan apua erilaisten viestien
          muotoilussa, esimerkiksi:
        </p>
        <ul>
          <li>
            Mitä halutaan sanottavan Kiitos-sivulla tai tilauksen saatteessa?
          </li>
          <li>Millainen viesti laitetaan itse näytteiden mukana?</li>
          <li>
            Mitä muita palveluja asiakkaalle palvelupolun seuraavissa vaihessa
            tarjotaan?
          </li>
        </ul>
        <p>
          Jos palvelu avataan useammalle markkina-alueelle, tarvitaan lisäksi
          käännökset kaikista edellisistä sekä päätöksiä toimitustavoista ja
          -maksuista eri maihin.
        </p>
        <p>
          <strong>
            Koska pienistä asioista kasaantuu aina luultua isompi työ, palvelun
            käyttöönottoprojektiin sisältyy projektipäällikön palvelut. Teidän
            ei siten tarvitse huolehtia projektinjohdosta, vaan me pidämme
            huolen siitä, että tarvittavat tiedot tulevat siirretyksi ja että
            projekti pysyy sovitussa aikataulussa.
          </strong>
        </p>
      </Accordion>
      <Accordion
        title="Miten palvelu tuodaan esiin kotisivuillani?"
        trackId="miten-palvelu-tuodaan-esiin-kotisivuillani"
      >
        <p>
          Palvelu on widget-muotoinen ja se on helppo lisätä eri puolille
          sivustoa. Toimivaksi osoittautuneita paikkoja avauslinkeille ovat
          tärkeimmät landing paget mm. etusivu ja tuotesivut. Lisäksi palvelu on
          hyvä tuoda esiin päävalikossa ja footerissa.
        </p>

        <p>
          Mallipalapalvelulle luotava oma landing page toimii erinomaisena
          sisäänheittäjänä, jossa mallipalojen idea ja hyödyt myydään
          asiakkaalle ennen mallipalapalvelun avaamista. Tälle sivulle on helppo
          ohjata asiakkaat valikoista tai eri puolille sivustoa sijoitetuista
          bannerista. Sivulla voi avata myös palvelun hinnan, toimitustavat,
          hyvityskäytännöt yms.
        </p>

        <p>
          Sosiaalisen median kampanjoihin saadaan tehokkuutta linkittämällä
          suoraan mallipalapalvelun omalle landing pagelle. Sivun ylä-, keski-
          ja alaosaan sijoitetut "call to action" -painikkeet aukaisevat
          palvelun päävalikon sivuston päälle. Tästä asiakkaan on helppo edetä
          etsimään eri vaihtoehtoja ensin sisäistettyään palvelun toimintaidean.
        </p>

        <p>
          Ylivoimaisesti paras konversio mallipalojen tilaamiseen saadaan
          kaikesta huolimatta tuotesivuilta. Tuotesivut näkyvät hakukoneissa
          yleensä poikkeuksellisen hyvin ja niille monesti päädytään
          ensimmäisenä.{" "}
          <strong>
            Tuotesivulta mallipalapalvelu voidaan avata juuri kyseisen tuotteen
            kohdalle, jolloin asiakkaan on mahdollisimman helppo tilata
            mallipaloja.
          </strong>
        </p>

        <p>
          Tuotesivulla Tilaa mallipala –painike sijoitetaan näkyvälle paikalle
          mahdollisimman ylös, jotta se näkyy ilman skrollaamista. Jos
          sivustollasi on myös verkkokauppa, Tilaa mallipala –painike
          sijoitetaan mahdollisen Osta-painikkeen viereen.
        </p>
      </Accordion>
      <Accordion
        title="Meillä on jo verkkokauppa. Miksi en vain lisäisi mallipaloja verkkokauppaan myyntiin?"
        trackId="meilla-on-jo-verkkokauppa"
      >
        <p>
          <strong>
            Näin toimittaessa mallipalat sekoittavat verkkokaupan tuotehaku- ja
            järjestystoiminnot sekä vaikeuttavat varsinaisten tuotteiden
            löytämistä. Toisin sanoen jokainen tuote tulee kauppaan kahteen
            kertaan: mallipala + varsinainen tuote.
          </strong>
        </p>
        <p>
          Tuotteiden järjestäminen hinnan mukaan tuottaa aina ensin listauksen
          kaikista mallipaloista. Käyttäjän on myös vaikea huomata eroa
          verkkokaupan tuotteen ja mallipalan välillä.
        </p>
        <p>
          <strong>
            Kun asiaa lähestytään asiakkaan tilanteen kautta, huomataan myös,
            että useimmiten hän on täysin eri vaiheessa ostopolkuaan tilatessaan
            mallipaloja kuin myöhemmin tehdessään lopullista tilausta.
          </strong>{" "}
          Myös lähetyksen mukana toimitettavien dokumenttien on oltava erilaiset
          kuin varsinaisten tuotteiden toimituksessa.
        </p>
        <p>
          Handpicked Cherries –mallipalakauppa täydentää erittäin hyvin olemassa
          olevaa tai perustettavaa verkkokauppaa. Se voidaan asentaa avautumaan
          saumattomasti verkkokaupan tuotesivulta. Toimituksen mukana voidaan
          myös toimittaa verkkokaupan alekoodi, jolla asiakas saa myöhemmin
          hyvityksen varsinaisesta ostoksestaan.
        </p>
        <p>
          Asiakkaamme käyttävät palveluamme mm. Shopifyn ja Magenton kanssa.
        </p>
      </Accordion>
      <Accordion
        title="Pitääkö kuluttajan rekisteröityä palveluun?"
        trackId="pitaako-kuluttajan-rekisteroitya"
      >
        <p>Ei.</p>
        <p>
          Sen sijaan suunnittelijat, jälleenmyyjät tai vaikkapa lehdistön
          edustajat voidaan ohjata palvelun ilmaiseen versioon, joka vaatii
          käyttäjätilin luomisen.
        </p>
        <p>
          Tässäkään tapauksessa hänen ei tarvitse luoda erillisiä salasanoja tai
          käyttäjätunnuksia, koska tunnistautuminen tapahtuu tekstiviestitse
          välitettävällä koodilla.
        </p>
      </Accordion>
      <Accordion
        title="Miten saan jälleenmyyjät mukaan yhteistyöhön?"
        trackId="miten-saan-jalleenmyyjat-mukaan-yhteistyohon"
      >
        <p>
          Jälleenmyyjien sitouttaminen mallipalapalvelun tarjoamiseen on iso
          mahdollisuus, johon kannattaa panostaa. Koko palvelu on suunniteltu
          siten, että jälleenmyyjät saataisiin pysymään arvoketjussa mukana ja
          oikealla paikallaan.
        </p>
        <p>
          Keinona yhteistyöhön ovat ensinnäkin mahdollisuus asentaa palvelu
          jälleenmyyjän omille nettisivuille. Palvelu aukeaa jälleenmyyjän
          nettisivun päälle ja näyttäytyy käyttäjälle osana jälleenmyyjän
          nettisivua. Asiakas ei siis poistu sivustolta mihinkään ja tämä lienee
          ensimmäinen ehto sille, että jälleenmyyjäsi suostuu edes harkitsemaan
          yhteistyötä.
        </p>
        <p>
          Toisekseen voit luvata, että kaikki viestintä asiakkaan suuntaan
          tapahtuu myös jälleenmyyjän nimissä. Jos palvelu on asennettuna
          jälleenmyyjän sivuille tai asiakas käyttää tilaukseen tältä saamaansa
          alekoodia, mallipalapalvelun Kiitos-sivu sekä toimituksen mukana
          lähetettävä saate on personoitu siten, että asiakas kokee edelleen
          saavansa palvelua kyseiseltä jälleenmyyjältä.
        </p>
        <p>
          Etukoodit ovat tehokas tapa kytkeä jälleenmyyjiä mukaan
          markkinointityöhön. Etukoodeja voidaan luoda myymäläkohtaisesti tai
          jopa myyjäkohtaisesti. Näin pääsette yhteisesti seuraamaan ja
          palkitsemaan myyjiä siitä työstä, jonka juuri he ovat tehneet myynnin
          edistämiseksi.
        </p>
      </Accordion>
      <Accordion
        title="Kuka omistaa palvelun myötä syntyvän asiakasrekisterin?"
        trackId="kuka-omistaa-asiakasrekisterin"
      >
        <p>
          Asiakasrekisteri on aina palvelumme ostaneen asiakasyrityksen
          omaisuutta. Handpicked Cherries toimii rekisterin ylläpitäjänä ja
          vastaa sen tietoturvasta. Oikeus rekisterin sisällön hyödyntämiseen on
          ainoastaan sen omistajalla.
        </p>
      </Accordion>
    </>
  );
}

export default Faq;
