import React from "react";

function BlockSubtitle({ children, white, gray, minHeight }) {
  return (
    <div
      className={`font-bold px-8 mb-2 leading-tight ${
        minHeight ? "lg:min-h-5" : ""
      } ${white ? "text-white" : ""} ${gray ? "text-gray" : ""}`}
    >
      {children}
    </div>
  );
}

export default BlockSubtitle;
