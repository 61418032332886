import React from "react";
import Helmet from "react-helmet";

function SEO({ config }) {
  return (
    <Helmet title={config.title}>
      <title>{config.title}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={config.description} />
      <meta name="image" content="/images/handpickedcherries-og-meta.png" />
      {config.url && <meta property="og:url" content={config.url} />}
      {config.title && <meta property="og:title" content={config.title} />}
      {config.description && (
        <meta property="og:description" content={config.description} />
      )}
      {config.image && <meta property="og:image" content={config.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {config.title && <meta name="twitter:title" content={config.title} />}
      {config.description && (
        <meta name="twitter:description" content={config.description} />
      )}
      {config.image && <meta name="twitter:image" content={config.image} />}

      <link rel="canonical" href={config.url} />
    </Helmet>
  );
}

export default SEO;
