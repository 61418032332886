import React from "react";

import "./Tickmarks.css";

function Tickmarks({ items }) {
  return (
    <ul className="Tickmarks">
      {items.map((item, i) => (
        <li className="py-2" key={i}>
          {item}
        </li>
      ))}
    </ul>
  );
}

export default Tickmarks;
