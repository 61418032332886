import React from "react";

function HeroTitle({ children, center }) {
  return (
    <h1
      className={`
        text-3xl
        md:text-4xl
        lg:text-5xl
        ${center ? "text-center" : ""}
      `}
    >
      {children}
    </h1>
  );
}

export default HeroTitle;
