import React, { useState } from "react";
import "./Accordion.css";

function Accordion({ title, trackId, children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="py-4 px-6 bg-lightgreen rounded-lg mb-4">
      <div
        onClick={() => {
          if (!isOpen && trackId && window.gtag) {
            window.gtag("event", "accordion_open", {
              trackId
            });
          }
          setIsOpen(!isOpen);
        }}
        className="flex items-center justify-between"
      >
        <div className="font-bold text-xl">{title}</div>
        <div className="underline text-red cursor-pointer ml-4">
          {isOpen ? "Piilota" : "Näytä"}
        </div>
      </div>
      {isOpen ? <div className="Accordion-content mt-4">{children}</div> : null}
    </div>
  );
}

export default Accordion;
